import React, { useEffect, useState } from 'react'; // , useContext
// import { Link } from 'react-router-dom';
import DisplayRegistrationRequests from '../../components/DisplayRegistrationRequests';
import axiosInstance from "../../helpers/Axios";

const defaultList: IRequest[] = [];


interface IRequest {
  registrationRequestId: number;
  eventId: string;
  firstName: string;
  lastName: string;
  birthdate: string;
  address: string;
  street: string;
  postalCode: string;
  city: string;
  phoneNumber: string;
  emailParent1: string;
  emailParent2: string;
  isVisible: boolean;
  dateCreation: Date; 
}

const Courses = () => {

  const [list, setList] = useState(defaultList);
  const [visible, setVisible] = useState(true);
  const loading = list === defaultList && visible;
  
useEffect(() => {
  if(loading)
    axiosInstance.get("/registrationrequest")
      .then(response => {
        //console.log('response', response);

        setList(response.data);//.map((courseObj : ICourseObj) => courseObj.course))
        // console.log("réponse",response.data);
        // setUserProfile(response.data);
        // setLocation(response.data.location);
      })
      .catch((err) => {
        const error = err.response === undefined ?
          'Server in not reachable'
          : err.response.data;
        console.log('error', error);
        setVisible(false);
      });
    }
 );

 console.log("list",list);

  return (
    <div id="courses">
      <div id="title">
        <h1>Inscriptions reçues</h1>
      </div>
      <div id="content">
        {
        loading ?
        <p>Chargement en cours...</p>
        :
        visible ?
          <DisplayRegistrationRequests requests={list} title="Liste des demandes d'inscription"/>
          :
            <div>
              <p>Le système d'inscription est actuellement en panne.</p>
            </div>
            }
      </div>
    </div>
  )
};

export default Courses;
