import React from 'react';
// import { Link } from 'react-router-dom';

const Blog = () => {

  return (
    <div id="blog">
      <div id="title">
        <h1>Blog</h1>
      </div>
      <div id="content">
      </div>
    </div>
  )
};

export default Blog;
