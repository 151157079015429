import React, { useState } from 'react'; //, useContext, useEffect 
import { Form, Input, Button, Label } from 'reactstrap';
import { newsletter as contactStudents } from '../context/actions/auth/contact';
import '../css/displaycontactform.scss';

interface IContactForm {
    course: undefined | string;
    subject: string;
    body: string;
}

const defaultContactForm = {
    course: undefined,
    subject: "",
    body: ""
}


const onSubmit = (e: React.FormEvent, form: IContactForm) => {
    e.preventDefault();
    if (form?.subject.length < 1 || form?.body.length < 1) {
        alert("Attention, il faut mettre un sujet et un corps à l'email");
    }
    else {
        console.log("form", form);
        contactStudents(form);
        alert(`La newsletter a été envoyée à tout le monde`);
    }
};



const DisplayNewsletterForm = () => {
    const [form, setForm] = useState<IContactForm>(defaultContactForm);

    
    const onChange = (e: React.FormEvent<HTMLInputElement>): void => {
        // setError(undefined);
        setForm({ ...form, [e.currentTarget.id]: e.currentTarget.value });
        console.log("form", form);
    };

    return (
        <Form className="contact-form content-width" method="post" onSubmit={(e) => onSubmit(e, form)}>
            <h2>Envoyer une newsletter à tout le monde : </h2>
            <div className="contact-form__mail">
                <div className="flex">
                    <Label>Sujet : </Label>
                    <Input placeholder="Newsletter 1 : lancement de la saison 2023/2024" id="subject" name="subject" onChange={onChange} />
                </div>
                <Input type="textarea" id="body" name="body" onChange={onChange}
                    placeholder="Bonjour à tous. Voici notre première newsletter ..." />

                <Button
                    className='button pointer'
                    type="submit"
                >
                    <span className="text">Envoyer</span>

                </Button>
            </div>
        </Form >
    )
}

export default DisplayNewsletterForm;

