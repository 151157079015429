import React from 'react';

interface Props {
  onMenuClick: () => void;
}

const Hamburger = ({ onMenuClick }: Props) => (
  <button id="header-toggle" type="button" className="navbar-toggle" onClick={() => onMenuClick()}>
    <span className="icon-bar" />
    <span className="icon-bar" />
    <span className="icon-bar" />
  </button>
);

export default Hamburger;
