import React from 'react';
// import { Link } from 'react-router-dom';

const Birthday = () => {

  return (
    <div id="birthday">
      <div id="title">
        <h1>Anniversaire</h1>
      </div>
      <div id="content">
      </div>
    </div>
  )
};

export default Birthday;
