import React from 'react'; //, useContext, useEffect 
import '../css/stagedetail.scss';

const StageDetail = () => {
    return (
        <div id="stage-detail">
            <h2>Stage de cirque à Burdinne</h2>
            <p>Du 21/10/2024 au 24/10/2024 de 09:00 à 16:00</p>
            <p>Ecole de Burdinne - Burdinne 60€</p>

            <h3>Stage d'initiation de cirque</h3>
            <h4>Du 21 au 25 octobre 2024</h4>
            <p><i>En collaboration avec le service ATL de Burdinne</i></p>
            <p>Nous découvrirons l’art du cirque selon notre âge et notre développement. Nous aurons l'opportunité de développer notre agilité,
            notre créativité et notre confiance en nous, tout en s'amusant !</p>

            <h4>Pour les enfants de 2,5 ans à 12 ans.</h4>

            <p><b>Lieu :</b> Ecole de Burdinne<br/>
              Rue de la Fontaine - Burdinne</p>
            <p><b>Horaires :</b> Animations de 9h à 16h<br/>
              Garderie gratuite de 8h à 18h</p>
            <p><b>Prix :</b> 60€<br/>
              (Tarif dégressif 5€ pour les enfants suivants).</p>
            <p><b>Infos et réservations :</b> Les réservations se font via le Portail parents du site de la Commune.<br/>
              https://burdinne.guichet-citoyen.be/portail_parent/</p>
            <p><b>Contact :</b> Coordinatrice ATL<br/>
              085/51.97.19 ou atl@burdinne.be</p>
            <p>atl@burdinne.be</p>
            <p><b>Ecole de Burdinne</b> Rue de la Fontaine, Burdinne</p>
        </div>
    )
}

export default StageDetail;

