import React from 'react';
// import { Link } from 'react-router-dom';

const Infos = () => {

  return (
    <div id="infos">
      <div id="title">
        <h1>Infos</h1>
      </div>
      <div id="content">
      </div>
    </div>
  )
};

export default Infos;
