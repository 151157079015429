import React from 'react'; //, useContext, useEffect, useState
import { Form } from 'reactstrap';
import '../css/displayregistrationrequests.scss';

interface IRequest {
    registrationRequestId: number;
    eventId: string;
    firstName: string;
    lastName: string;
    birthdate: string;
    address: string;
    street: string;
    postalCode: string;
    city: string;
    phoneNumber: string;
    emailParent1: string;
    emailParent2: string;
    isVisible: boolean;
    dateCreation: Date; 
}

interface IProps {
    requests: undefined | IRequest[];
    title: string;
}


// const niceTime = (time: string) => {
//     if (time === null)
//         return '';
//     const tokens = time.split(':');
//     return `${tokens[0]}h${tokens[1]}`;
//   };

  const monthToString = (month: number) => {
    const monthList = ['Janvier', 'Février', 'Mars',
    'Avril','Mai','Juin',
    'Juillet','Août','Septembre',
    'Octobre','Novembre','Décembre'];
    return monthList[month];
  };
  const niceDate = (date: string) => {
    if (date === null)
        return '';
    const start = new Date(date);
    return `${start.getDate()} ${monthToString(start.getMonth())} ${start.getFullYear()} à ${start.getHours()}h${start.getMinutes()}`;
  };


const DisplayRegistrationRequests = ({requests, title}:IProps) => {
    // const [error, setError] = useState(undefined);

    // useEffect(() => {
    // });
    return (
        <Form className="registration-form content-width" method="post">
            <h2>Consulter les inscriptions pour le cours ci-dessous : </h2>
            <div className="registration-form-course" >
            {requests !== undefined ?
            <div>
                <div className="registration-subscription-attempts">
                            {/* <p>Cours choisi: {course}</p> */}
                            {requests.length > 0 ? 
                                requests.map((cc) => {
                                    const classes = `registration-subscription-attempt ${cc.isVisible ? '' : 'hidden'}`;
                                    return (
                                        <div key={cc.registrationRequestId} className={classes}>
                                            <p><span className="attempt-id">{cc.registrationRequestId}</span> {niceDate(cc.dateCreation.toString())}</p>
                                            <p>{cc.firstName} {cc.lastName} né(e) le {cc.birthdate}</p>
                                            <p>{cc.address}</p>
                                            {/* ({cc.street} {cc.postalCode} {cc.city}) */}
                                            <p>{cc.emailParent1} {cc.emailParent2} {cc.phoneNumber}</p>
                                            <p>{cc.eventId}</p>
                                        </div>
                                    );
                                })
                                :
                                <p>Aucun cours correspondant au choix n'a été trouvé</p>
                        }
                </div>
            </div>
        : <div/>
            }
            </div>
        </Form>
    )
}

export default DisplayRegistrationRequests;

