import React from 'react'; //, useContext, useEffect 
import IContact from '../interfaces/IContact';
import '../css/courselist.scss';


interface ICourse {
    shortName: string;
    name: string;
    day: IDay;
    isVisible: boolean;
    nbMaxRegistrations: number;
    startTime: string;
    endTime: string;
  }

  interface IStage {
    shortName: string;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
  }
  
  interface IDay {
    name: string;
  }

  interface ICourseObj {
    course: ICourse;
    subscribes: IContact[];
  }

interface IProps {
    courses: undefined | ICourseObj[];
    stages: undefined | IStage[];
    title: string;
}

const niceTime = (time: string) => {
    if (time === null)
        return '';
    const tokens = time.split(':');
    return `${tokens[0]}h${tokens[1]}`;
  };

const CourseList = ({stages, courses, title}:IProps) => {


    return (
        <div id="course-list">
            <h2>{title}</h2>
            {courses !== undefined ?
                            courses.filter((li) => li.course.isVisible).map((li) =>{
                                const schedule = li.course.day?.name !== '' ? ` (${li.course.day?.name} de ${niceTime(li.course.startTime)} à ${niceTime(li.course.endTime)})`  : '';
                                return (
                                    <p key={li.course.shortName} className="course">{li.course.name}{schedule}</p>
                                );
                            })
                    : <div/>
                    }

        </div>
    //     <Form className="registration-form content-width" method="post" onSubmit={(e) => onSubmit(e, form)} >
    //         <h2>Merci de remplir votre fiche de pré-inscription : </h2>
    //         <div className="registration-form-course" >
    //         {courses !== undefined ?
    //             <select name="course" id="course" value={course} onChange={updateCourse}>
    //             <option key='default' value=''>{title}</option>
    //             {
    //                 courses.filter((li) => li.course.isVisible && li.subscribes.length < li.course.nbMaxRegistrations).map((li) =>{
    //                     const schedule = li.course.day?.name !== '' ? ` (${li.course.day?.name} de ${niceTime(li.course.startTime)} à ${niceTime(li.course.endTime)})`  : '';
    //                     return (
    //                         <option key={li.course.shortName} value={li.course.shortName}>{li.course.name}{schedule}</option>
    //                     );
    //                 })
    //             }
    //         </select>
    //         : <div/>
    //         }
    //             {stages !== undefined ?
    //             <select name="stage" id="stage" value={stage} onChange={updateStage}>
    //             <option key='default' value=''>{title}</option>
    //             {
    //                 stages.map((li) =>{
    //                     const schedule = li.startDate !== undefined ? ` (Du ${nicePeriod(li.startDate,li.endDate)})`  : '';
    //                     return (
    //                         <option key={li.shortName} value={li.shortName}>{li.description}{schedule}</option>
    //                     );
    //                 })
    //             }
    //         </select>
    //         : <div/>
    //         }
    //         </div>
            
    //         <div className="registration-form-is-a-child">
    //         <Label className="checkbox" htmlFor="isachild">
    //                 <Input type="checkbox" onClick={() => setIsAChild(!isAChild)} checked={isAChild} id="isachild" name="isachild" />
    //             J'inscris mon enfant
    //           </Label>
    //         </div>

    //         <div className="registration-form-name registration-form-item">
    //             <Label>Prénom et nom (de l'enfant)<span className="mandatory">*</span></Label>
    //             <div className="flex">
    //                 <div className="registration-form-name__first-name">
    //                     <Input type="text" id="firstName" name="firstName" autoComplete="first-name" onChange={onChange} placeholder="Prénom" />
    //                     {/* <p>Prénom</p> */}
    //                 </div>
    //                 <div className="registration-form-name__last-name">
    //                     <Input type="text" id="lastName" name="lastName" autoComplete="family-name" onChange={onChange} placeholder="Nom" />
    //                     {/* <p>Nom</p> */}
    //                 </div>
    //             </div>
    //         </div>
    //         <div className="registration-form-birthdate registration-form-item">
    //             <Label>Date de naissance (de l'enfant)<span className="mandatory">*</span></Label>
    //             <Input type="text" id="birthDate" name="birthDate" autoComplete="date" onChange={onChange} />
    //         </div>
    //         <div className="flex space-between addresses registration-form-item">
    //             <div className="registration-form-address">
    //             <Label>Adresse<span className="mandatory">*</span></Label>
    //                 <div className="flex">
    //                     <p>Rue : </p>
    //                     <Input type="text" id="street" name="street" autoComplete="street" onChange={onChange} />                        
    //                 </div>
    //                 <div className="flex">
    //                     <p>Code postal : </p>
    //                     <Input type="text" id="postalCode" name="postalCode" autoComplete="postalCode" onChange={onChange} />                        
    //                 </div>
    //                 <div className="flex">
    //                     <p>Ville : </p>
    //                     <Input type="text" id="city" name="city" autoComplete="city" onChange={onChange} />
    //                 </div>
    //             </div>
    //         </div>
    //         <div className="registration-form-phone registration-form-item">
    //             <Label>Numéro de téléphone<span className="mandatory">*</span></Label>
    //             <Input type="text" id="phoneNumber" name="phoneNumber" autoComplete="phone" onChange={onChange} />
    //         </div>
    //         {isAChild ?
    //         <div className="registration-form-email registration-form-item">
    //         <Label>Adresses e-mail<span className="mandatory">*</span></Label>
    //         <div className="flex">
    //             <p>Parent 1 : </p>
    //             <Input type="text" className="emailAddress" id="emailAddress1" name="emailAddress1" autoComplete="email" onChange={onChange} />
    //         </div>
    //         <div className="flex">
    //             <p>Parent 2 : </p>
    //             <Input type="text" className="emailAddress" id="emailAddress2" name="emailAddress2" autoComplete="email" onChange={onChange} />
    //         </div>
    //         {/* placeholder="Email-address" */}
    //         {hasEmailError ?
    //             <Label id="emailError" name="emailError" className="errors">
    //                 {emailError()}
    //             </Label>
    //             : <span />
    //         }
    //     </div>
    //     :
    //     <div className="registration-form-email registration-form-item">
    //     <Label>Adresses e-mail<span className="mandatory">*</span></Label>
    //         <Input type="text" className="emailAddress" id="emailAddress" name="emailAddress1" autoComplete="email" onChange={onChange} />
    //     {/* placeholder="Email-address" */}
    //     {hasEmailError ?
    //         <Label id="emailError" name="emailError" className="errors">
    //             {emailError()}
    //         </Label>
    //         : <span />
    //     }
    // </div>

    //     }
    //         <div className="registration-form-submit">
    //             <Button
    //                 className='button pointer'
    //                 //   disabled={loading}
    //                 type="submit"
    //             //   loading={loading.toString()}
    //             >
    //                 {/* <i className="fa fa-spinner fa-spin"></i> */}
    //                 <span className="text">S'inscrire</span>
    //             </Button>
    //             {/* <Input type="button" value="Soumettre" /> */}
    //         </div>
    //     </Form>
    )
}

export default CourseList;

