import React from 'react';
// import logo from './logo.svg';
import Header from './components/Header';
import Footer from './components/Footer';
import Routes from './constants/Routes';
import IRoute from './interfaces/IRoute';
// import Footnote from './components/Footnote';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './css/creacirque.scss';

const App = () => {
  const RenderRoute = (route: IRoute) => (
    route.exact
      ? <Route path={route.url} key={route.url} exact component={route.comp} />
      : <Route path={route.url} key={route.url} component={route.comp} />
  );

  return (
    <div id="app">
      <Router>
        <Header />
        <div id="page">
        <Switch>
          {Routes.map((route) => RenderRoute(route))}
        </Switch>
        </div>
        {/* <Legend /> */}
        {/* <NewLegend /> */}
        {/* <Footnote /> */}
        <Footer />
      </Router>
    </div>
  );
}

export default App;
