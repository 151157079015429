import React, { useState} from 'react'; //, useContext, useEffect 
import { Form } from 'reactstrap'; // ,Label,  Input, Button
//import { SEND_MAIL_ERROR, SEND_MAIL_LOADING, SEND_MAIL_SUCCESS } from "../constants/actionTypes";
//import axiosInstance from "../helpers/Axios";
// import { register as registerUser } from '../context/actions/auth/register';
//import { GlobalContext } from '../context/Provider';
import IContact from '../interfaces/IContact';
import '../css/displayregistrations.scss';
import '../css/fiche.scss';


interface ICourse {
    shortName: string;
    name: string;
    day: IDay;
    isVisible: boolean;
    startTime: string;
    endTime: string;
  }

  interface IStage {
    shortName: string;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
  }
  
  interface IDay {
    name: string;
  }

  interface ICourseObj {
    course: ICourse;
    subscribes: IContact[];
  }
  
interface IProps {
    courses: undefined | ICourseObj[];
    stages: undefined | IStage[];
    title: string;
}

const niceDate = (date: string) => {
    if (date === null)
        return '';
    const start = new Date(date);
    return `${start.getDate()}/${start.getMonth()+1}/${start.getFullYear()}`;
    // à ${start.getHours()}h${start.getMinutes()}
  };


const niceTime = (time: string) => {
    if (time === null)
        return '';
    const tokens = time.split(':');
    return `${tokens[0]}h${tokens[1]}`;
  };

  const monthToString = (month: number) => {
    const monthList = ['Janvier', 'Février', 'Mars',
    'Avril','Mai','Juin',
    'Juillet','Août','Septembre',
    'Octobre','Novembre','Décembre'];
    return monthList[month];
  };
  const nicePeriod = (startDate: string, endDate: string) => {
    if (startDate === null)
        return '';
    const start = new Date(startDate);
    const end = new Date(endDate);
    if(start.getMonth() === end.getMonth() && start.getDate() < end.getDate())
    {
        return `${start.getDate()} au ${end.getDate()} ${monthToString(start.getMonth())}`;
    }
    return '';
  };

//const context = useContext(GlobalContext);
//const registerDispatch = context?.registerDispatch;
const emptyCourseList : ICourseObj[] = [];

const DisplayRegistrations = ({stages, courses, title}:IProps) => {
    // const [error, setError] = useState(undefined);
    const [course, setCourse] = useState("");
    const [stage, setStage] = useState("Choisir votre stage");
    const [currentCourse, setCurrentCourse] = useState(emptyCourseList);

    // useEffect(() => {
    // });
    console.log("course: ",course);
    console.log("stage: ",stage);

    const print = () => window.print();

    const updateCourse: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setCourse(e.target.value);
        const filtered = courses?.filter((c) => c.course.shortName === e.target.value);
        console.log('current course', currentCourse);
        setCurrentCourse(filtered !== undefined ? filtered : emptyCourseList);
        // setForm({ ...form, [e.target.id]: e.target.value });
      };

      const updateStage: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setStage(e.target.value);
        // setForm({ ...form, [e.target.id]: e.target.value });
      };

    return (
        <Form className="registration-form content-width" method="post">
            <h2>Consulter les inscriptions pour le cours ci-dessous : </h2>
            <div className="registration-form-course" >
            {courses !== undefined ?
            <div>
                <div className="flex">
                    <select name="course" id="course" value={course} onChange={updateCourse}>
                    <option key='default' value=''>{title}</option>
                    {
                        courses.filter((li) => li.course.isVisible).map((li) =>{
                            const schedule = li.course.day?.name !== '' ? ` (${li.course.day?.name} de ${niceTime(li.course.startTime)} à ${niceTime(li.course.endTime)})`  : '';
                            return (
                                <option key={li.course.shortName} value={li.course.shortName}>{li.course.name}{schedule}</option>
                            );
                        })
                    }
                    </select>
                    <input id="print" type="button" value="Imprimer la fiche" onClick={print} />
                </div>
                <div className="registration-subscribers">
                    {course !== undefined && course !== '' ?
                        <div className="registration-subscribe">
                            {/* <p>Cours choisi: {course}</p> */}
                            {currentCourse !== undefined && currentCourse.length > 0 ? 
                                currentCourse.map((cc) => (
                                    <div>
                                        {
                                            cc.subscribes.length > 0 ?
                                            <div>
                                                <table id="fiches" style={{width: "100%"}}>
                                                    <thead>
                                                    <tr className="mauve">
                                                            <td colSpan={2}>{cc.course.shortName.toUpperCase().replaceAll('-',' ')}</td>
                                                            <td colSpan={3}>{cc.course.day?.name} {niceTime(cc.course.startTime)} - {niceTime(cc.course.endTime)}
                                                            {/* const schedule = li.startDate !== undefined ? ` (Du ${nicePeriod(li.startDate,li.endDate)})`  : ''; */}
                        {/* return ( */}
                            {/* <option key={li.shortName} value={li.shortName}>{li.description}{schedule}</option> */}
                        {/* ); */}

                                                                
                                                                </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td colSpan={5} className="align-right">ANNEE 2024/2025</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr><td><br /></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td></tr>
                                                    </tbody>
                                                <thead>
                                                        <tr>
                                                            <th>NOM</th>
                                                            <th>PRENOM</th>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cc.subscribes.map((contact) => (
                                                        <tr>
                                                            <th>{contact.lastName.toUpperCase()}</th>
                                                            <th>{contact.firstName.toUpperCase()}</th>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                ))}
                                                    </tbody>
                                                    <tbody>
                                                        <tr><td><br /></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td></tr>
                                                    </tbody>
                                                    <thead>
                                                        <tr>
                                                            <th>NOM</th>
                                                            <th>PRENOM</th>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cc.subscribes.map((contact) => (
                                                        <tr>
                                                            <th>{contact.lastName.toUpperCase()}</th>
                                                            <th>{contact.firstName.toUpperCase()}</th>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                ))}
                                                        
                                                    </tbody>

                                                    <tbody>
                                                        <tr><td><br /></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td></tr>
                                                    </tbody>
                                                    <thead>
                                                        <tr className="mauve">
                                                            <td colSpan={2}>{cc.course.shortName.toUpperCase().replaceAll('-',' ')}</td>
                                                            <td colSpan={3}>{cc.course.day?.name} {niceTime(cc.course.startTime)} - {niceTime(cc.course.endTime)}
                                                            {/* const schedule = li.startDate !== undefined ? ` (Du ${nicePeriod(li.startDate,li.endDate)})`  : ''; */}
                        {/* return ( */}
                            {/* <option key={li.shortName} value={li.shortName}>{li.description}{schedule}</option> */}
                        {/* ); */}

                                                                
                                                                </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td colSpan={5} className="align-right">ANNEE 2024/2025</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr><td><br /></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td></tr>
                                                    </tbody>
                                                    <thead>
                                                    <tr>
                                                            <th>NOM</th>
                                                            <th>PRENOM</th>
                                                            <th className="pink">IMAG.</th>
                                                            <th className="green">ASS.</th>
                                                            <th className="dark-pink">PAY.</th>
                                                            <th className="yellow">SOLD.</th>
                                                            <th colSpan={2}>TEL 1</th>
                                                            <th colSpan={2}>TEL 2</th>
                                                            <th colSpan={10}>EMAIL</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cc.subscribes.map((contact) => {
                                                            const pink = contact?.student?.imageRight ? "pink" : "";
                                                            const green = contact?.student?.insurance ? "green" : "";
                                                            const darkpink = contact?.student?.payment ? "dark-pink" : "";
                                                            const yellow = contact?.student?.hasRemainingSold ? "yellow" : "";
                                                            return (
                                                        <tr>
                                                            <th>{contact.lastName.toUpperCase()}</th>
                                                            <th>{contact.firstName.toUpperCase()}</th>
                                                            <td className={pink}></td>
                                                            <td className={green}></td>
                                                            <td className={darkpink}></td>
                                                            <td className={yellow}></td>
                                                            <td colSpan={2}>{contact.phoneNumber}</td>
                                                            <td colSpan={2}>{contact.phoneNumber !== contact.phoneNumber2 ? contact.phoneNumber2 : ''}</td>
                                                            <td colSpan={10}>{contact.email}</td>
                                                        </tr>

/*
                                                            <td>{contact.phoneNumber}</td>
                                                            <td>{contact.phoneNumber !== contact.phoneNumber2 ? contact.phoneNumber2 : ''}</td>
                                                            <td>{(contact.phoneNumber !== contact.phoneNumber3 && contact.phoneNumber2 !== contact.phoneNumber3)  ? contact.phoneNumber3 : ''}</td>
                                                            <td>{contact.email ? contact.email : contact.email1}</td>
                                                            <td>{contact.email2}</td>
 */

                                                )}
                                                )}
                                                        
                                                    </tbody>
                                                </table>
                                                <table id="full" style={{width: "100%"}}>
                                                    <thead>
                                                        <tr className="bleu-vert">
                                                            <td></td>
                                                            <td></td>
                                                            <td>{cc.subscribes.length}</td>
                                                            <td>ELEVES</td>
                                                            <td>màj 05/06/2024</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th>Mise à jour</th>
                                                            <th></th>
                                                            <th>N°</th>
                                                            <th>NOM</th>
                                                            <th>PRENOM</th>
                                                            <th className="bleu-vert">INSCR.</th>
                                                            <th className="bleu-vert">CONN</th>
                                                            <th className="pink">IMAG.</th>
                                                            <th className="green">ASS.</th>
                                                            <th className="dark-pink">PAY.</th>
                                                            <th className="yellow">SOLD.</th>
                                                            <th>ADRESSE</th>
                                                            <th>TEL 1</th>
                                                            <th>TEL 2</th>
                                                            <th>TEL 3</th>
                                                            <th>EMAIL 1</th>
                                                            <th>EMAIL 2</th>
                                                            <th>D.N</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cc.subscribes.map((contact) => {
                                                            const pink = contact?.student?.imageRight ? "pink" : "";
                                                            const green = contact?.student?.insurance ? "green" : "";
                                                            const darkpink = contact?.student?.payment ? "dark-pink" : "";
                                                            const yellow = contact?.student?.hasRemainingSold ? "yellow" : "";
                                                            return (
                                                        <tr>
                                                            <td>{niceDate(contact.dateModification)}</td>
                                                            <td></td>
                                                            <td>{contact.contactId}</td>
                                                            <td>{contact.lastName}</td>
                                                            <td>{contact.firstName}</td>
                                                            <td className="bleu-vert"></td>
                                                            <td></td>
                                                            <td className={pink}></td>
                                                            <td className={green}></td>
                                                            <td className={darkpink}></td>
                                                            <td className={yellow}></td>
                                                            <td>{contact.address}</td>
                                                            <td>{contact.phoneNumber}</td>
                                                            <td>{contact.phoneNumber !== contact.phoneNumber2 ? contact.phoneNumber2 : ''}</td>
                                                            <td>{(contact.phoneNumber !== contact.phoneNumber3 && contact.phoneNumber2 !== contact.phoneNumber3)  ? contact.phoneNumber3 : ''}</td>
                                                            <td>{contact.email ? contact.email : contact.email1}</td>
                                                            <td>{(contact.email && contact.email1 !== contact.email) ? contact.email1 : (contact.email2 !== contact.email1 && contact.email2 !== contact.email ? contact.email2 : '')}</td>
                                                            <td>{niceDate(contact.birthdate)}</td>
                                                        </tr>
                                                )}
                                                )}
                                                        
                                                    </tbody>
                                                </table>

                                            {/* <p>Nombre d'inscrits : {cc.subscribes.length}</p>
                                            <ul>{cc.subscribes.map((contact) => (
                                                <li>{contact.firstName} {contact.lastName}</li>                                        
                                            ))}</ul> */}
                                            </div>
                                            :
                                            <p>Il n'y a pour l'instant aucun élève inscrits à ce cours</p>
                                        }
                                    </div>
                                ))
                                :
                                <p>Aucun cours correspondant au choix n'a été trouvé</p>
                        }
                        </div>
                        : <p className="error">Veuillez choisir un cours</p>}
                </div>
            </div>
        : <div/>
            }
                {stages !== undefined ?
                <select name="stage" id="stage" value={stage} onChange={updateStage}>
                <option key='default' value=''>{title}</option>
                {
                    stages.map((li) =>{
                        const schedule = li.startDate !== undefined ? ` (Du ${nicePeriod(li.startDate,li.endDate)})`  : '';
                        return (
                            <option key={li.shortName} value={li.shortName}>{li.description}{schedule}</option>
                        );
                    })
                }
            </select>
            : <div/>
            }

            </div>
        </Form>
    )
}

export default DisplayRegistrations;

