import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Links from '../constants/Links';
import Hamburger from '../constants/Hamburger';
import '../css/header.scss';

const Header = () => {
  const [highlighted, highlight] = useState(window.location.pathname.substring(1));
  const [show, setShow] = useState(false);

  const ShowHideMenu = (test: string, show: boolean) => {
    highlight(test);
    setShow(show);
  };

  return (
    <nav>
      <div className="navbar">
        <div className="navbar-header">
          <Link onClick={() => ShowHideMenu('', false)} className="home" to="/">
            <div className="flex">
              <p className="brand">Creacirque</p>
              <p className="brand_subtitle">Ecole du cirque de Namur</p>            
            </div>
          </Link>
        </div>
        <Hamburger onMenuClick={() => { ShowHideMenu(highlighted, !show); }} />
        <div id="navbar-menu" className={`navbar-collapse ${show ? '' : ' hidden'}`}>
          <ul className="nav navbar">
            {Links.map((link) => {
              return (
                <li key={link.name}>
                  <Link onClick={() => ShowHideMenu(link.url, false)} to={link.url} className={highlighted === link.url ? 'selected' : ''}>
                    {link.name}
                  </Link>
                </li>)
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
