// import React from "react";
//import { SEND_MAIL_ERROR, SEND_MAIL_LOADING, SEND_MAIL_SUCCESS } from "../../../constants/actionTypes";
import axiosInstance from "../../../helpers/Axios";

// const getDate = (dob: string) => {
//   if (dob === undefined)
//     return undefined;
//   const tokens: string[] = dob.split('/');
//   const year: string = tokens[2];
//   const month: string = tokens[1].length < 2 ? `0${tokens[1]}` : tokens[1];
//   const day: string = tokens[0].length < 2 ? `0${tokens[0]}` : tokens[0];
//   const newDob: string = `${year}-${month}-${day}`;
//   return newDob;
// };


interface IRegistrationForm {
  course: string;
  stage: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  phoneNumber: string;
  // address: string;
  street: string;
  postalCode: string;
  city: string;
  emailAddress: string;
  emailAddress1: string;
  emailAddress2: string;
  isachild: boolean;
  }

export const register = (form: IRegistrationForm) =>
//(dispatch: any) => 
{
//    dispatch({ type: SEND_MAIL_LOADING, });

    const newOrder = {
//        ProfilePicture:"/images/avatar-icon-images-4.jpg",
        FirstName:form?.firstName,
        LastName:form?.lastName,
        Email:form?.emailAddress,
        EmailParent1:form?.emailAddress1,
        EmailParent2:form?.emailAddress2,
        PhoneNumber:form?.phoneNumber,
        Address:`${form?.street} ${form?.postalCode} ${form?.city}`,
        Street:form?.street,
        PostalCode:form?.postalCode,
        City:form?.city,
        BirthDate:form?.birthDate,
        IsAchild:form?.emailAddress === ""
        // IdModification:3,
        // DateModification:getDate(form?.dob),
        // IdCreation:4,
        // DateCreation:getDate(form?.dob)
    };

    if(form?.course !== undefined)
    {
      axiosInstance.post(`/course/${form?.course}/register`, newOrder)      
      .then(response => {
  //      const idStr = response.data?.split('-')[0];
        // console.log('idstr',idStr);
  //      const id = idStr ? parseInt(idStr) : undefined;
        // console.log('id',id);
        // console.log('/auth/register -> response statuts', response.status)
        /*
        if (response.status === 201) {
            dispatch({
              type: SEND_MAIL_SUCCESS,
              payload: response.data,
              id: id,
            });
          }
          else {
            dispatch({
              type: SEND_MAIL_ERROR,
              payload: 'unable to register this user',
            });
          }
          */
        }
        )
        .catch((err) => {
          // const error = err.response === undefined ? 
          // 'Server in not reachable'
          // : err.response.data;
  //        const detailedError = error?.detail;
          /*
          dispatch({
            type: SEND_MAIL_ERROR,
            payload: detailedError !== undefined ? detailedError : error,
          })
  */
        }
        );  
    }
    
};
