import React, { useState } from 'react'; //, useContext, useEffect 
import { Form, Input, Button, Label } from 'reactstrap';
//import { SEND_MAIL_ERROR, SEND_MAIL_LOADING, SEND_MAIL_SUCCESS } from "../constants/actionTypes";
//import axiosInstance from "../helpers/Axios";
import { contact as contactStudents } from '../context/actions/auth/contact';
//import { GlobalContext } from '../context/Provider';
import '../css/displaycontactform.scss';


interface ICourse {
    shortName: string;
    name: string;
    day: IDay;
    isVisible: boolean;
    startTime: string;
    endTime: string;
}

interface IStage {
    shortName: string;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
}

interface IDay {
    name: string;
}


interface IContact {
    contactId: number,
    firstName: string,
    lastName: string
}

interface ICourseObj {
    course: ICourse;
    subscribes: IContact[];
}

interface IProps {
    courses: undefined | ICourseObj[];
    stages: undefined | IStage[];
    title: string;
}

interface IContactForm {
    course: undefined | string;
    subject: string;
    body: string;
}

const defaultContactForm = {
    course: undefined,
    subject: "",
    body: ""
}

const niceTime = (time: string) => {
    if (time === null)
        return '';
    const tokens = time.split(':');
    return `${tokens[0]}h${tokens[1]}`;
};

const monthToString = (month: number) => {
    const monthList = ['Janvier', 'Février', 'Mars',
        'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre',
        'Octobre', 'Novembre', 'Décembre'];
    return monthList[month];
};
const nicePeriod = (startDate: string, endDate: string) => {
    if (startDate === null)
        return '';
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (start.getMonth() === end.getMonth() && start.getDate() < end.getDate()) {
        return `${start.getDate()} au ${end.getDate()} ${monthToString(start.getMonth())}`;
    }
    return '';
};


const onSubmit = (e: React.FormEvent, form: IContactForm) => {
    e.preventDefault();
    if (form?.subject.length < 1 || form?.body.length < 1) {
        alert("Attention, il faut mettre un sujet et un corps à l'email");
    }
    else {
        console.log("form", form);
        contactStudents(form);
        alert(`Le mail a été envoyé à tous les enfants du cours ${form?.course}`);
    }
};

//const context = useContext(GlobalContext);
//const registerDispatch = context?.registerDispatch;
const emptyCourseList: ICourseObj[] = [];

const DisplayContactForm = ({ stages, courses, title }: IProps) => {
    const [form, setForm] = useState<IContactForm>(defaultContactForm);
    const [course, setCourse] = useState("");
    // const [error, setError] = useState(undefined);
    const [stage, setStage] = useState("Choisir votre stage");
    const [currentCourse, setCurrentCourse] = useState(emptyCourseList);

    // useEffect(() => {
    // });
    console.log("course: ", course);
    console.log("stage: ", stage);

    const updateCourse: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setCourse(e.target.value);
        const filtered = courses?.filter((c) => c.course.shortName === e.target.value);
        console.log('current course', currentCourse);
        setCurrentCourse(filtered !== undefined ? filtered : emptyCourseList);
        setForm({ ...form, [e.target.id]: e.target.value });
        console.log("form", form);
    };

    const updateStage: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setStage(e.target.value);
        setForm({ ...form, [e.target.id]: e.target.value });
    };

    const onChange = (e: React.FormEvent<HTMLInputElement>): void => {
        // setError(undefined);
        setForm({ ...form, [e.currentTarget.id]: e.currentTarget.value });
        console.log("form", form);
    };
    return (
        <Form className="contact-form content-width" method="post" onSubmit={(e) => onSubmit(e, form)}>
            <h2>Envoyer un e-mail à tous les éleves et parents du cours ci-dessous : </h2>
            <div className="contact-form-course" >
                {courses !== undefined ?
                    <div>
                        <select title={course} name="course" id="course" value={course} onChange={updateCourse}>
                            <option key='default' value=''>{title}</option>
                            {
                                courses.filter((li) => li.course.isVisible).map((li) => {
                                    const schedule = li.course.day?.name !== '' ? ` (${li.course.day?.name} de ${niceTime(li.course.startTime)} à ${niceTime(li.course.endTime)})` : '';
                                    return (
                                        <option key={li.course.shortName} value={li.course.shortName}>{li.course.name}{schedule}</option>
                                    );
                                })
                            }
                        </select>
                        <div className="contact-subscribers">
                            {course !== undefined && course !== '' ?
                                <div className="contact-subscribe">
                                    {/* <p>Cours choisi: {course}</p> */}
                                    {currentCourse !== undefined && currentCourse.length > 0 ?
                                        <div className="contact-form__mail">
                                            <div className="flex">
                                                <Label>Sujet : </Label>
                                                <Input placeholder="Création de compte et inscription à CréaCirque" id="subject" name="subject" onChange={onChange} />
                                            </div>
                                            <Input type="textarea" id="body" name="body" onChange={onChange}
                                                // onChange={onChange} 
                                                placeholder="Merci pour votre inscription à ... Vous pouvez accéder à votre compte via le lien suivant : ..." />

                                            <Button
                                                className='button pointer'
                                                //   disabled={loading}
                                                type="submit"
                                            //   loading={loading.toString()}
                                            >
                                                {/* <i className="fa fa-spinner fa-spin"></i> */}
                                                <span className="text">Envoyer</span>

                                            </Button>
                                        </div>
                                        // currentCourse.map((cc) => (
                                        //     <div>
                                        //         {
                                        //             cc.subscribes.length > 0 ?
                                        //             <div>

                                        //             <p>Nombre d'inscrits : {cc.subscribes.length}</p>
                                        //             <ul>{cc.subscribes.map((contact) => (
                                        //                 <li>{contact.firstName} {contact.lastName}</li>                                        
                                        //             ))}</ul>
                                        //             </div>
                                        //             :
                                        //             <p>Il n'y a pour l'instant aucun élève inscrits à ce cours</p>
                                        //         }
                                        //     </div>
                                        // ))
                                        :
                                        <p>Aucun cours correspondant au choix n'a été trouvé</p>
                                    }
                                </div>
                                : <p className="error">Veuillez choisir un cours</p>}
                        </div>
                    </div>
                    : <div />
                }
                {stages !== undefined ?
                    <select name="stage" id="stage" value={stage} onChange={updateStage}>
                        <option key='default' value=''>{title}</option>
                        {
                            stages.map((li) => {
                                const schedule = li.startDate !== undefined ? ` (Du ${nicePeriod(li.startDate, li.endDate)})` : '';
                                return (
                                    <option key={li.shortName} value={li.shortName}>{li.description}{schedule}</option>
                                );
                            })
                        }
                    </select>
                    : <div />
                }

            </div>
        </Form>
    )
}

export default DisplayContactForm;

