import React from 'react'; //, { useState, useContext, useEffect } 
// import { Label, Form, Input, Button } from 'reactstrap';
//import { SEND_MAIL_ERROR, SEND_MAIL_LOADING, SEND_MAIL_SUCCESS } from "../constants/actionTypes";
//import axiosInstance from "../helpers/Axios";
// import { register as registerUser } from '../context/actions/auth/register';
//import { GlobalContext } from '../context/Provider';
import '../css/profile.scss';

  
interface IProfil {
  contactId: number;
  firstName: string;
  lastName: string;
  birthdate: string;
  address: string;
  street: string;
  postalCode: string;
  city: string;
  phoneNumber: string;
  phoneNumber2: string;
  phoneNumber3: string;
  email: string;
  imageURI: string;
  parents: IProfil[];
  isVisible: boolean;
  dateCreation: Date;
  dateModification: Date;
  children: IProfil[]; 
}

interface IProps {
    profil: undefined | IProfil;
    isChild: boolean;
}

  const monthToString = (month: number) => {
    const monthList = ['Janvier', 'Février', 'Mars',
    'Avril','Mai','Juin',
    'Juillet','Août','Septembre',
    'Octobre','Novembre','Décembre'];
    return monthList[month];
  };
  const niceDate = (date: string) => {
    if (date === null)
        return '';
    const start = new Date(date);
    const dateOnly = `${start.getDate()} ${monthToString(start.getMonth())} ${start.getFullYear()}`;
    const timeOnly = `${start.getHours()}h${start.getMinutes()}`;
    return false ? `${dateOnly} à ${timeOnly}` : dateOnly;
  };


const Profile = ({profil, isChild}:IProps) => {
    const children = profil?.children ?? [];
    const parents = children.length > 0 ? children[0].parents : [];
    const mainClass = isChild ? 'profil-child' : 'profil-parent'
    return (
        <div key={profil?.contactId} className={mainClass}>
            <h3>{profil?.firstName} {profil?.lastName}</h3>
            {parents !== undefined ? parents.map((parent) => {
              const isSameParent = parent.firstName === profil?.firstName && parent.lastName === profil?.lastName
              if (isSameParent)
              return <div/>
              else
               return <div>{parent.firstName} {parent.lastName}</div>;
               }) : <div/>}
            <div className="profil-children">
            {children?.map((child) => (<Profile profil={child} isChild={true} />))}
            </div>
            <p>{profil?.birthdate != null ? niceDate(profil?.birthdate??''):''}</p>
        </div>
    )
}

export default Profile;

