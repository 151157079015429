import React from 'react'; // , useContext
// import { Link } from 'react-router-dom';
import DisplayNewsletterForm from '../../components/DisplayNewsletterForm';

const Newsletter = () => {

 
  return (
    <div id="courses">
      <div id="title">
        <h1>Newsletter</h1>
      </div>
      <div id="content">
          <DisplayNewsletterForm />
      </div>
    </div>
  )
};

export default Newsletter;
