import Home from '../pages/Home';
import Courses from '../pages/Courses';
import Stages from '../pages/Stages';
import Photos from '../pages/Photos';
import Birthday from '../pages/Birthday';
import Infos from '../pages/Infos';
import Blog from '../pages/Blog';
import AdminContact from '../pages/Admin/Contact';
import AdminNewsletter from '../pages/Admin/Newsletter';
import AdminCourses from '../pages/Admin/Courses';
import AdminStages from '../pages/Admin/Stages';
import AdminSubscriptions from '../pages/Admin/Subscriptions';
import YourAccount from '../pages/YourAccount';

const Routes = [
  { url: '/', exact: true, comp: Home },
  { url: '/les-cours', exact: false, comp: Courses },
  { url: '/stages-week-ends', exact: false, comp: Stages },
  { url: '/photos', exact: false, comp: Photos },
  { url: '/anniversaire', exact: false, comp: Birthday },
  { url: '/infos', exact: false, comp: Infos },
  { url: '/blog', exact: false, comp: Blog },
  { url: '/your-account', exact: false, comp: YourAccount },
  { url: '/admin-contact', exact: false, comp: AdminContact },
  { url: '/admin-newsletter', exact: false, comp: AdminNewsletter },
  { url: '/admin-cours', exact: false, comp: AdminCourses },
  { url: '/admin-stages', exact: false, comp: AdminStages },
  { url: '/admin-inscriptions', exact: false, comp: AdminSubscriptions },
];

export default Routes;
