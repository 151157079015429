// import React from "react";
//import { SEND_MAIL_ERROR, SEND_MAIL_LOADING, SEND_MAIL_SUCCESS } from "../../../constants/actionTypes";
import axiosInstance from "../../../helpers/Axios";

// const getDate = (dob: string) => {
//   if (dob === undefined)
//     return undefined;
//   const tokens: string[] = dob.split('/');
//   const year: string = tokens[2];
//   const month: string = tokens[1].length < 2 ? `0${tokens[1]}` : tokens[1];
//   const day: string = tokens[0].length < 2 ? `0${tokens[0]}` : tokens[0];
//   const newDob: string = `${year}-${month}-${day}`;
//   return newDob;
// };


interface IContactForm {
  course: undefined | string;

  subject: string;
  body: string;
  }

  export const contact = (form: IContactForm) =>
  {
  
      const newOrder = {
          Subject:form?.subject,
          Body:form?.body,
      };
  
      console.log('go appel course/.../contact');
  
      if(form?.course !== undefined)
      {
        axiosInstance.post(`/course/${form?.course}/contact`, newOrder)      
        .then(response => {
          }
          )
          .catch((err) => {
          }
          );  
      }
      
  };

  
export const newsletter = (form: IContactForm) =>
{

    const newOrder = {
        Subject:form?.subject,
        Body:form?.body,
    };

    console.log('go appel course/newsletter');

      axiosInstance.post(`/course/newsletter`, newOrder)      
      .then(response => {
        }
        )
        .catch((err) => {
        }
        );      
};
